import React from "react";
import CompraTickets from "./tickets_compra";
import * as app from "../fetch/fetchApi";
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from "react-helmet-async";
const BackgroundImage = (data) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    var wid = window.innerWidth
  }
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <link rel="preload" as="image" href={"../../static/portada_lasestacas.webp"} />
        </Helmet>
      </HelmetProvider>

      <article id="node-1612" class="node node-page view-mode-full" style={{ position: 'inherit' }}>
        <div class="body field">
          <div class="portada-logo" style={{ position: 'absolute', zIndex: 10, left: '50%', transform: 'translate(-50%, 0)', width: '100%' }}>
            {wid > 1200 ? <LazyLoadImage width="648" height="325" class="show-for-large-up" style={{ width: '648px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', maxHeight: '42vh' }} width='45%' src={"../static/LE_2301_LogoTravelers_Desktop.png"} /> : ''}
            {wid < 1200 && wid > 500 ? <LazyLoadImage class="show-for-medium-only" style={{ width: '56%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10vh', maxHeight: '100vh' }} width='56%' src={"../static/LE_2301_LogoTravelers_Mobile.png"} /> : ''}
            {wid < 600 ? <LazyLoadImage height="288" width="382" class="show-for-small-only" style={{ width: '382px', marginLeft: 'auto', marginRight: 'auto', marginTop: '20vh', }} width='90%' src={"../static/LE_2301_LogoTravelers_Tablet.png"} /> : ''}
            <CompraTickets data={data} />
          </div>
        </div>
        <div class="field field-name-field-img-background field-type-image field-label-hidden field-wrapper inicio">
          {wid < 450 ? <LazyLoadComponent>  <LazyLoadImage width="450" height="600" src={"../static/portada_lasestacas.webp"} style={{ height: 'inherit', objectFit: 'cover' }} alt="Las estacas" title="Las estacas" /></LazyLoadComponent> : <LazyLoadComponent>  <LazyLoadImage width="1440" height="1098" src={"../../static/portada_lasestacas.webp"} style={{ height: 'inherit', objectFit: 'cover' }} alt="Las estacas" title="Las estacas" /></LazyLoadComponent>}
        </div>
      </article>
    </div>
  );
};

export default BackgroundImage;