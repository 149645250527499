import React, { useEffect, useState, } from "react";
import Banner from "../components/banner";
import CartTimer from "../components/cart_timer";
import MaintenanceBanner from "../components/maintenance_banner";
import BackgroundImage from "../components/background_image";
import { withPrefix } from "gatsby";
import PreFooter from "../components/prefooter";
import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";
import HeaderCard from "../components/header_card";
import ContentCard from "../components/content_card";
import CardEvento from "../components/card_eventos";
import ContentInstagram from "../components/content_instragram";
import CarouselCardAtracciones from "../components/carousel_card_atracciones";
import BannerMobile from "../components/banner_mobile";
import ModalComplementa from "../components/modal_complementa";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Helmet, } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import $, { getJSON } from 'jquery'
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Mapa from "../components/mapa";
import CompraTicketsMobile from "../components/tickets_compra_mobile";
import TagManager from 'react-gtm-module'
import { BUILD_NUMBER } from '../../build-number'
const axios = require('axios');
const jar = app.jar;
const client = wrapper(axios.create({ jar }));

const Header = () => {
  const [datos, setData] = useState([])
  const [datosInstagram, setDataInstagram] = useState([])
  const [datosEventos, setDataEvento] = useState([])
  const [datosMenu, setDataMenu] = useState({})
  const [datosMenuFooter, setDataMenuFooter] = useState({})
  const [datosTickets, setDataTickets] = useState([{
    idproducto: "",
    precio: "",
    titulo: ""
  }, {
    idproducto: "",
    precio: "",
    titulo: ""
  }])

  const isBrowser = typeof window !== "undefined"
  let width;
  var dummyAtracciones;
  function callbackFuncWithData(data) {
    setData(data)
    dummyAtracciones = data;
  }

  if (typeof window !== 'undefined') {
    window.updateValues = () => {
      getDatosTickets()
      getDatosAtracciones()
      getDatosEventos()
      getDatosFooterMenu()
      getDatosInstragram()
      getDatosMainMenu()
    }
  }

  const getDatosTickets = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataTickets(window.tickets)
      }
    }
  }

  const getDatosEventos = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataEvento(window.dummyEventos)
      }
    }
  }

  const getDatosFooterMenu = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataMenuFooter(window.dummyFooterMenu)
      }
    }
  }

  const getDatosInstragram = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataInstagram(window.dummyInstagram)
      }
    }
  }
  const getDatosMainMenu = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataMenu(window.dummyMenu)
      }
    }
  }

  const getDatosAtracciones = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setData(window.dummyAtracciones)
      }
    }
  }
  useEffect(() => {
    getDatosAtracciones()
    getDatosEventos()
    getDatosFooterMenu()
    getDatosInstragram()
    getDatosMainMenu()
    getDatosTickets()
  }, [])

  if (isBrowser) {
    width = window.innerWidth
    $(document).ready(function () {
      localStorage.setItem('TipoHab', 'hotel')
      const datosRequire = require('../../static/drupal/dummy-atracciones.json')
    }
    );
  }
  const loading = <p>Loading</p>

  const buildNumber = BUILD_NUMBER

  const [isMaintenance, setMaintenance] = useState(null)

  async function peticion() {
await client.post(app.url + '/node-api/m22_get_maintenance_mode', {
      withCredentials: true,
      headers: {
        crossDomain: true,
        'Content-Type': 'application/json'
      },
    })
        .then(function (response) {
          var maintenance = response.data.is_maintenance;
          setMaintenance(maintenance)
        })
        .catch(function (error) {
          console.log(error)
        });
  }

  useEffect(() => {
   peticion()
  }, [])

  return (
      <div>
        {
            isMaintenance &&
            <MaintenanceBanner/>
        }
        {
          !isMaintenance &&
            <div>
              <Helmet>
                {width > 1200 ? <link rel="preload" fetchpriority="high" as="image"
                                      href={"../../static/le_desktop_tripadvisor.webp"}/> : ''}
                {width > 450 && width < 1200 ? <link rel="preload" fetchpriority="high" as="image"
                                                     href={"../../static/le_mobile_tripadvisor.webp"}/> : ''}
                {width < 450 ? <link rel="preload" fetchpriority="high" as="image"
                                     href={"../../static/le_tablet_tripadvisor.webp"}/> : ''}
                <script src={withPrefix('../../static/drupal/dummy-tickets.js?version=' + buildNumber)}
                        type="text/javascript"/>
                <script src={withPrefix('../../static/drupal/dummy-atracciones.js?version=124')} type="text/javascript"/>
                <script src={withPrefix('../../static/drupal/dummy-footer-menu.js?version=124')} type="text/javascript"/>
                <script src={withPrefix('../../static/drupal/dummy-main-menu.js?version=124')} type="text/javascript"/>
                <script src={withPrefix('../../static/drupal/dummy-eventos.js?version=124')} type="text/javascript"/>
                <script src={withPrefix('../../static/drupal/dummy-instagram.js?version=124')} type="text/javascript"/>
                <script src={"https://code.jquery.com/jquery-2.2.4.min.js"}
                        integrity={"sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44="} crossOrigin="anonymous"></script>
                <script src={"../../static/drupal/cart_timer.js?version=124"} type="text/javascript"/>
                <link rel="shortcut icon" href="https://cms.lasestacas.com/sites/default/files/favicon.ico"
                      type="image/vnd.microsoft.icon"/>
                <link type="text/css" rel="stylesheet" href={"../../static/drupal/cart_timer.css?version=124"}/>
              </Helmet>
              {width < 1100 ? <Banner data={datosMenu}/> : <BannerMobile data={datosMenu}/>
              }
              {datosTickets.titulo != '' ? < BackgroundImage data={datosTickets}/> : ''}
              <main className={'l-main header-visible'} role="main">
                <CartTimer/>
                <ModalComplementa/>

                <div className={' main'}>
                  <div className="paddingMain">
                    <section
                        className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
                      {width < 450 && datosTickets.titulo != '' ? <CompraTicketsMobile data={datosTickets}/> : ''}
                      <div
                          className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                        <HeaderCard/>
                        <div className="">
                          <div className="d-none d-md-block"><ContentCard data={datos}/></div>
                          {datos.length > 0 ?
                              <div className="d-block d-md-none"><CarouselCardAtracciones data={datos}/></div> : ''}
                        </div>
                        <div className="view-footer"><a href={app.url + "/atracciones"}
                                                        className="more-content btn-tex-mob-sb links">Ver todos las
                          atracciones <img width='28' loading="lazy" height='16'
                                           src={app.url + "/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"}/></a>
                        </div>
                      </div>

                    </section>
                    <Mapa/>

                    <section
                        className="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
                      <h2 className="block-title">Eventos</h2>
                      <div
                          className="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-d1eb1bfdcbb295210c7992b81e1319cc">
                        <div className="view-content slick-initialized slick-slider">


                          <div style={{
                            margin: '0 auto',
                            color: '#333',
                            display: 'block'
                          }}>
                            {datosEventos.length > 0 ? <CardEvento data={datosEventos}/> : ''}
                          </div>

                        </div>
                        <div className="view-footer"><a href={app.url + "/eventos"}
                                                        className="more-content btn-tex-mob-sb links">Ver todos los
                          eventos <img width='28' loading="lazy" height='16'
                                       src={app.url + "/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"}/></a>
                        </div>
                      </div>
                      {datosInstagram.length > 0 ? <ContentInstagram data={datosInstagram}/> : ''}

                    </section>
                  </div>

                </div>

              </main>

              <PreFooter/>
              <LazyLoadComponent>
                <footer id="foot" background="https://lasestacas.com/sites/all/themes/tickets/2019/Img/Footer_Mobile.jpg"
                        className={'l-footer prueba panel lazy-background'} role={'contentinfo'}>

                  <div className={'footer'}>
                    <NewsLetter/>
                    <MenuFooter data={datosMenuFooter}/>
                    <Politicas/>
                    <Redes/>
                  </div>
                  <Copyright/>
                </footer>
              </LazyLoadComponent>
              <span id={'mostrarTooltip'} data-selector="tooltip-kxqp5vmd1" id="tooltip-kxqp5vmd1"
                    className="tooltip tip-top custom-open" role="tooltip"
                    style={{visibility: 'visible', width: 'auto', inset: '610.594px auto auto 739.969px',}}>En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.<span
                  className="nub"></span></span>

            </div>
        }
      </div>
  );
};

export default Header;