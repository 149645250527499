import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import * as app from "../fetch/fetchApi";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class CardEvento extends Component {
   render() {
      var datos = this.props.data;
      const settings = {
         dots: false,
         infinite: true,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1,
      };
      return (
         <div>
            <Slider {...settings}>
               {datos.map((data) => (
                  <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                     <div class="views-row views-row-6 views-row-even views-row-last slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1">
                        <div class="field-content eventImg"><LazyLoadImage src={data.event_image_url} loading="lazy" width="600" height="330" alt="" /></div>
                        <div class="event-container views-fieldset heightCardEventos" data-module="views_fieldsets" style={{ padding: '18px 18px' }}>
                           <div className="heightTexto">
                              <h3 class="field-content title-3-b">{<div dangerouslySetInnerHTML={{ __html: data.event_title }} />}</h3>
                           </div>
                           <div>
                              <p class="field-content event-body paragraph-sb">{data.body_text}</p>
                           </div>
                           <fieldset class="date-wrapper emphasis-v views-fieldset d-lg-block d-md-none d-none" data-module="views_fieldsets" style={{ marginTop: '25px' }}>
                              <legend> <span class="fieldset-legend">Fecha</span></legend>
                              <div class="fieldset-wrapper d-grid d-lg-block">
                                 <fieldset class="start-date views-fieldset" data-module="views_fieldsets">
                                    <legend> <span class="fieldset-legend">Inicio</span></legend>
                                    <div class="fieldset-wrapper"> {<div dangerouslySetInnerHTML={{ __html: data.fecha_evento_inicio }} />}</div>
                                 </fieldset>
                                 <fieldset class="end-date views-fieldset" data-module="views_fieldsets">
                                    <legend> <span class="fieldset-legend">Fin</span></legend>
                                    <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">{<div dangerouslySetInnerHTML={{ __html: data.fecha_evento_fin }} />}</span></div>
                                 </fieldset>
                              </div>
                           </fieldset>
 <div style={{ display: 'inherit' }}>
                              <div className="d-lg-none d-md-flex d-none">
                                 <fieldset class="start-date views-fieldset" style={{ border: '0px' }} data-module="views_fieldsets">
                                    <legend> <span class="fieldset-legend">Inicio</span></legend>
                                    <div class="fieldset-wrapper"> {<div dangerouslySetInnerHTML={{ __html: data.fecha_evento_inicio }} />}</div>
                                 </fieldset>
                                 <fieldset class="end-date views-fieldset" style={{ border: '0px' }} data-module="views_fieldsets">
                                    <legend> <span class="fieldset-legend">Fin</span></legend>
                                    <div class="fieldset-wrapper"> <span class="field-content event-date paragraph-sb">{<div dangerouslySetInnerHTML={{ __html: data.fecha_evento_fin }} />}</span></div>
                                 </fieldset>
                              </div>
                              <a href={app.url + data.url} style={{ width: '124px', height: '48px' }} class="boton-azul botonCardEventos me-3 mb-1 text-center" tabindex="0">Más info </a>
                           </div>
                        </div>
                     </div>
                  </div>
               ))
               }
            </Slider>
         </div>
      );
   }
}