import React from "react";
import $ from "jquery";
import * as app from "../fetch/fetchApi";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const Mapa = () => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
        var wid = window.innerWidth
        $(document).ready(function () {
            setTimeout(() => {
                if ($('body').hasClass('front')) {
                    $('#map-container').mousemove(function (e) {
                        if ("ontouchstart" in document.documentElement) {
                        } else {
                            var imgClone = $(this).children('img').clone();
                            if ($('#modalMap img').length < 1) {
                                imgClone.appendTo($(this).children('#modalMap'))
                            }
                            var widthScreen = $('#map-container').width();
                            var heightContainer = $('#map-container').height();
                            var blockYpos = $(this).offset().top;
                            var xPos = e.pageX;
                            var yPos = e.pageY - blockYpos;
                            if ($(window).width() < 1360) {
                                $('#modalMap').css({
                                    'top': yPos,
                                    'left': xPos + 20,
                                    'display': 'block'
                                });
                                $('#modalMap img').css({
                                    'top': (yPos * -2) + 50,
                                    'left': (xPos * -2) + 100,
                                    'display': 'block'
                                });
                            } else {
                                var gap = parseInt($('body main > div').css('padding-left'));
                                var scaleImgX = $(this).children('#modalMap').children('img').width();
                                var scaleImgY = $(this).children('#modalMap').children('img').height();
                                var containerX = $(this).width();
                                var containerY = $(this).height();
                                var relationX = (scaleImgX / containerX);
                                var relationY = (scaleImgY / containerY);
                                var relation = ((xPos - gap) * relationX);
                                $('#modalMap').css({
                                    'top': yPos + 20,
                                    'left': xPos + 20 - gap,
                                    'display': 'block'
                                });
                                $('#modalMap img').css({
                                    'top': (yPos * (relationX * -1)) + 50,
                                    'left': (relation * -1),
                                    'display': 'block'
                                });
                            }
                        }
                    });
                    $('#map-container').mouseout(function (e) {
                        $('#modalMap').css('display', 'none');
                    });

                    function lightbox_sitio(sitio) {
                        $.magnificPopup.open({
                            items: {
                                src: '/mapa/' + sitio
                            },
                            type: 'iframe',
                            srcAction: 'iframe_src'
                        });
                    }

                    function hash_lightbox(hashtext) {
                        if ($('#map-estacas').contents().find('.mapplic-layer a[href="' + window.location.hash + '"]').length == 1) {
                            var sitio = $('#map-estacas').contents().find('.mapplic-layer a[href="' + window.location.hash + '"]').attr('data-path');
                            lightbox_sitio(sitio)
                        }
                    }

                    if ($(window).width() >= 768) {
                        $('.block-block-5').html('<iframe id="map-estacas" src="/mapa/index.html" style="width: 100%; height: 670px; border: none; margin-left: auto; margin-right: auto;"></iframe>');
                        $('body').on('tooltip', function () {
                            $('#map-estacas').contents().find('a.mapplic-tooltip-title').on('click', function () {
                                lightbox_sitio($(this).attr('data-path'));
                            })
                        });

                        if ($('body').hasClass('front')) {
                            $('.block-block-5').show();
                            $('body').on('mapLoaded', function () {
                                $('#map-estacas').contents().find('a.mapplic-pin').click(function () {
                                    lightbox_sitio($(this).attr('data-path'));
                                    window.location.hash = $(this).attr('data-location');
                                })
                                if (window.location.hash.length > 0) {
                                    hash_lightbox(window.location.hash)
                                }
                                window.onhashchange = hash_lightbox;

                                $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').show();
                                $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').show();
                                if (window.location.hash.length > 0) {
                                    $('#map-estacas').attr('src', $('#map-estacas').attr('src') + window.location.hash)
                                }
                            })
                        } else {

                            $('.block-block-5').show();
                            $('body').on('mapLoaded', function () {
                                $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').hide();
                                $('#map-estacas').contents().find('.mapplic-levels-select a').click(function () {
                                    $('body').css('background-color', '#fff')
                                    $('.main-container.container').remove();
                                    $('#block-system-main').html('')
                                    $('#block-system-main').css('min-height', '0px')
                                    $('.block-block-5').css('margin-top', '0px');
                                    $('body').scrollTop(0);
                                    $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').show();

                                    $('#map-estacas').contents().find('a.mapplic-pin').click(function () {
                                        lightbox_sitio($(this).attr('data-path'));
                                    })
                                });
                            })
                        }
                    }
                }
            }, 2000);

        });
    }

    return (
        <section class="block block-block block-block-10 block-block-10 d-none d-md-block">
            <h2 class="block-title mapa">Mapa</h2>
            <div id="map-container">
                {wid < 450 ? <LazyLoadImage width="363" height="186"
                                            data-src={app.url + "/sites/all/themes/tickets/2019/Img/mapaSitio.jpg"}
                                            alt="Mapa" id="newmap"
                                            src={app.url + "/sites/all/themes/tickets/2019/Img/mapaSitio.webp"}
                                            data-zoom="https://lasestacas.com/sites/all/themes/tickets/2019/Img/Map.jpg"
                                            data-pagespeed-url-hash="31059146" typeof=""/> :
                    <LazyLoadImage class="mapaStyle" width="1138" height="585"
                                   data-src={app.url + "/sites/all/themes/tickets/2019/Img/Map.jpg"} alt="Mapa"
                                   id="newmap" src={app.url + "/sites/all/themes/tickets/2019/Img/mapaSitio.webp"}
                                   data-zoom="https://lasestacas.com/sites/all/themes/tickets/2019/Img/Map.jpg"
                                   data-pagespeed-url-hash="31059146" typeof=""/>}
                <div id="modalMap" class="modal"></div>
            </div>
            <div class="linkto">
                <a href={app.url + "/como-llegar"} class="btn-tex-mob-s llegada" title="Como llegar">
                    Cómo llegar <LazyLoadImage width="28" height="16" class="lazy" loading="lazy"
                                               src={app.url + "/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"}/>
                </a>
            </div>
        </section>
    );
};

export default Mapa;