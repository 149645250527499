import React from "react";
import CardInstagram from "./card_instagram";


const ContentInstagram = ({data}) => {
  let datos = [];
  datos= data;

  let showData=()=>{
    console.log(datos.length)
    //<CardInstagram data={datos} />
    for (let index = 0; index < datos.length; index++) {
     
      
    }
  }
  return (
    <section class="block block-views block-views-instagram-slider-block block-views-instagram-slider-block my-5">
        <h2 class="tituloInteres text-center mb-4">Esto es lo que gente como tú está viviendo ahora en Las Estacas:</h2>
        <div class="view view-instagram-slider view-id-instagram_slider view-display-id-block view-dom-id-b5e4d9ec403a47d141f0352d2dcc075d">
            <div class="view-content">
            {datos.map((item) => (
         <CardInstagram data={item} />
      ))}
                
                 
            </div>
        </div>
    </section>
  );
};

export default ContentInstagram;