import React from "react";
import Card from "./card_atracciones";

const ContentCard = ({data}) => {

  const cardsGrupo=(datos)=>{
    let render = []
    for (let index = 0; index < datos.length; index++) {

        
          
            render.push(        <Card id={index+1} data={data}/>
            );
          
        
        
        
    }
    return render;
}
  return (
    <div class="view-content">
        {cardsGrupo(data)}
    </div>
  );
};

export default ContentCard;