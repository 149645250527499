import React from "react";
import $ from "jquery"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CardInstagram = (data) => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
    $(document).ready(
        function()
         {
                  var isTouch = ('ontouchstart' in document.documentElement);
                  var isTouching = false;
                  var instagramContainer = $('.view-instagram-slider .view-content .ig-post:eq(0)').width();
                  $('.view-instagram-slider .view-content').children('.ig-post:eq(0),.ig-post:eq(1),.ig-post:eq(2),.ig-post:eq(3),.ig-post:eq(4),.ig-post:eq(5),.ig-post:eq(6)').clone().addClass('clone').appendTo($('.view-instagram-slider .view-content'));
                  var diff;
                  if (isTouch) {
                      $('.view-instagram-slider .view-content').bind("mousedown touchstart", function(event){ isTouching = true;});
                      $('.view-instagram-slider .view-content').bind("mouseup touchend", function(event){ isTouching = false;});
                  }
                  //Se saca la medida de las imagenes para agregarles una clase correspondiente y ajustar su tamaño al contenedor cuadrado
                  $('.view-instagram-slider .img-container img').each(function(index, el) {
                      var heightImg = parseInt($(this).attr('height'));
                      var widthImg = parseInt($(this).attr('width'));
                      if (heightImg < widthImg) {
                          $(this).addClass('horizontal');
                      }
                      else if (heightImg > widthImg) {
                          $(this).addClass('vertical');
                      }else {
                          $(this).addClass('square');
                      }
                  });
      
                  //Se inicia intervalo de tiempo
                  if ($('.view-instagram-slider ').length > 0) {
                      setInterval(
                      function instagramSlider(){
                          // Calcula el ancho del dispositivo
                          var screenWidth = $(window).width();
                          // Calcula el width de una card.
                          var igPostWidth = $('.view-instagram-slider .view-content .ig-post:eq(0)').outerWidth(true);
                          // Calcula el ancho que tiene en pantalla el contenedor de todas las cards.
                          var widthContainer =  $('.view-instagram-slider .view-content').width();
                          // Calcula el scroll que queda restandole el width de una card.
                          var scrollWidthContainer = $('.view-instagram-slider .view-content')[0].scrollWidth - igPostWidth; 
                          // Calcula cuanto scroll se ha hecho sobre el contenedor.
                          var scrollCounter = $('.view-instagram-slider .view-content').scrollLeft();
                          // Calcula cuantas cards caben en el contenedor
                          var postsInsideScreen = Math.floor(widthContainer/igPostWidth);
                          // Calcula el espacio que sobra entre las cards que caben y el contenedor
                          var gapBetweenPosts = (widthContainer - (igPostWidth*postsInsideScreen))/2;
                          /* Se valida si se esta presionando la pantalla para que en caso de que si, el movimiento automatico del slider
                           * se pause hasta que la pantalla deje de tocarse, esto para evitar una mala experiencia del usuario
                           */
                          if (!isTouching) {
                              /* Si no se ha llegado al final se calcula el scroll que se ha hecho, si el usuario mueve el scroll con los dedos,
                                *  lo calcula y la siguiente animacion la justa a donde el usuario dejo el slider
                                */
                              if ((widthContainer + scrollCounter)  >= ((scrollWidthContainer)-(igPostWidth*(6-postsInsideScreen)))) {
                                  $('.view-instagram-slider .view-content ').animate( { scrollLeft: '0' }, 0);
                                  var center = igPostWidth-gapBetweenPosts;
                                  $('.view-instagram-slider .view-content ').animate( { scrollLeft: '+='+center }, 500);
                              }else {
                              /** Si no se ha llegado al final se calcula el scroll que se ha hecho, si el usuario mueve el scroll con los dedos,
                               *  lo calcula y la siguiente animacion la justa a donde el usuario dejo el slider
                               **/
                                  if (diff == undefined) {
                                      diff=0;
                                  }else {
                                      diff = scrollCounter/igPostWidth;
                                  }
                                  if (Number.isInteger(diff) || diff == 0) {
                                      var center = igPostWidth-gapBetweenPosts;
                                      $('.view-instagram-slider .view-content ').animate( { scrollLeft: '+='+center }, 500);
                                  }else {
                                      var intNumber = Math.ceil(diff);
                                      diff = intNumber + 1;
                                      $('.view-instagram-slider .view-content ').animate( { scrollLeft: (igPostWidth*diff)-gapBetweenPosts }, 500);
                                  }
                              }
                          }
                      }, 5000);
                  }
            
      });
    }

  return (
    <div class="ig-post">
        <div class="img-container">
            <div class="image insta-post">
            <LazyLoadImage src={data.data.image_ig} class="b-lazy square b-loaded" width="1080" height="1080" alt=""/>
            </div>
        </div>
        <div class="hover-action views-fieldset" data-module="views_fieldsets">
           <div style={{display: 'flex'}}>
            <span class="likes-count">
                <span class="number likes">{data.data.likes_count}</span>
            </span> 
            <span class="comment-count container">
                <span class="number comments">{data.data.comment_count}</span>
            </span>
            </div>
        </div>
    </div>
   );
};

export default CardInstagram;