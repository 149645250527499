import React from "react";
import $ from 'jquery';

const MaintenanceBanner = () => {
    return (
        <div>
            <div id="maintenance-banner" style="display: flex;align-items: center;justify-content: center;margin-top: -100px;">
                <img src="/static/maintenance.svg" alt=""/>
            </div>
        </div>
    );
};

export default MaintenanceBanner;