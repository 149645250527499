import React from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";


const PreFooter = () => {
  return (
    <section class="pre-footer"> 

    <section class="block block-block block-block-14 block-block-14">
    <LazyLoadComponent >

        <div class="bloque-contacto lazyload">
            <LazyLoadComponent>
            <div class="img-contacto lazyload"></div>
            </LazyLoadComponent>
            <div class="contenido-contacto">
                <div class="titulo-contacto">
                <h2 class="title-2-sai mb-1">Contacto</h2>
            </div>
            <div class="texto-contacto " style={{width:'317px'}}>
                <p class="paragraph-sa ">Nos interesa mucho lo que pienses, no olvides seguirnos en redes sociales. Si tienes alguna duda contáctanos por nuestras redes, con gusto atenderemos.</p>
            </div>
        </div>
        <div class="social-networks-label hide">Síguenos por</div>
        <div class="social-networks">
            <ul>
                <li>
                    <a href="https://instagram.com/lasestacas_oficial/" target="_blank">
                        <LazyLoadComponent>
                        <div class="instagram lazyload">
                            <i></i>
                        </div>
                        </LazyLoadComponent>
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/lasestacas.parquenatural" target="_blank">
                        <LazyLoadComponent>
                        <div class="facebook lazyload">
                            <i></i>
                        </div>
                        </LazyLoadComponent>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/LasEstacas_" target="_blank">
                        <LazyLoadComponent>
                        <div class="twitter lazyload">
                            <i></i>
                        </div>
                        </LazyLoadComponent>
                    </a>
                </li>
                <li>
                    <a href="mailto:informes@lasestacas.com" target="_blank">
                        <LazyLoadComponent>
                        <div class="email lazyload">
                            <i></i>
                        </div>
                        </LazyLoadComponent>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    </LazyLoadComponent>

</section> 
</section>
  );
};

export default PreFooter;